.active-link-sidebar {
  color: red;
  /* Colore per il link attivo */
}

.inactive-link-sidebar {
  color: black;
  /* Colore per il link inattivo */
}

.App {
  background-color: white;
}

.animate {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}